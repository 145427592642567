export function setItem(
  name,
  value,
  highPriority,
  days = 730,
  path = '/',
  httpOnly,
  sameSite = true
) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()};`;
  }
  document.cookie = `${name}=${value}${expires}; path=${path};${
    httpOnly ? ' httpOnly;' : ''
  };${highPriority ? 'Priority=High;' : ''}${
    !sameSite ? 'SameSite=None; Secure' : ''
  }`;
}

export function getItem(k) {
  const nameEQ = `${k}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];

    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
}

export function removeItem(k) {
  return setItem(k, '', true, -1);
}

export function hasItem(k) {
  return !!getItem(k);
}

export function clear() {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
}

export function decodeValue(value) {
  const base64regex =
    /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  const isBase64 = base64regex.test(value);

  let decodedValue;

  try {
    if (isBase64) {
      // eslint-disable-next-line no-undef
      decodedValue = Base64.decode(value);
    } else {
      decodedValue = decodeURIComponent(value);
    }
  } catch (e) {
    decodedValue = value;
  }

  return decodedValue;
}
