import EventEmitter from 'eventemitter3';

const emits = {
  ALERT: 'alert',
  MODAL: 'modal',
  USER_AUTH: 'user_auth',
};

Object.freeze(emits);

export default new EventEmitter();

export { emits };
