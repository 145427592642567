const CookiesEnum = {
  TOURNAMENTS_POPUP_SHOWN: 'tournaments_popup_shown',
  CYBER_POPUP_SHOWN: 'cyber_popup_shown',
  INDEPENDENCE_POPUP_SHOWN: 'independence_popup_shown',
  ERROR_BOUNDARY_CATCH: 'error_boundary_catch',
  COOKIE_POLICY: 'cookie_policy',
};

Object.freeze(CookiesEnum);

export default CookiesEnum;
