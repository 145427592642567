import axios from 'axios';
import { getToken } from '../helpers/user.helper';

export const dynamicBaseURL = process.env.NEXT_PUBLIC_BASE_URL_V1;
export const baseURLV1 = process.env.NEXT_PUBLIC_API_BASE_URL_V1;

export const axiosInstanceV1 = axios.create({
  baseURL: baseURLV1,
  timeout: 60000,
});

export const dynamicAxiosInstance = axios.create({
  baseURL: dynamicBaseURL,
  timeout: 60000,
});

const successResponse = (response) => response;

const errorResponse = (error) => Promise.reject(error);

const setHeaders = (reqConfig) => reqConfig;

axiosInstanceV1.interceptors.request.use(
  async (config) => config,
  (error) => Promise.reject(error)
);

axiosInstanceV1.interceptors.request.use(setHeaders);
axiosInstanceV1.interceptors.response.use(successResponse, errorResponse);

dynamicAxiosInstance.interceptors.request.use(
  async (config) => config,
  (error) => Promise.reject(error)
);

dynamicAxiosInstance.interceptors.request.use(setHeaders);
dynamicAxiosInstance.interceptors.response.use(successResponse, errorResponse);

export const getReqConfig = (cookies) => {
  const headerObj = { headers: {} };
  const token = getToken(cookies);

  if (token) {
    headerObj.headers['Access-Token'] = token;
  }

  return headerObj;
};
