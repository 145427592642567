import { axiosInstanceV1, getReqConfig } from './axiosInstance';
import { parseResponse } from './helper';
import LocalesEnum from '../enums/locales.enum';

const BASE = '/Tag';

export async function getMetasForThePage_req(
  cookies = {},
  url,
  val,
  locale = LocalesEnum.DEFAULT
) {
  return parseResponse(
    axiosInstanceV1.post(
      `${locale}/${BASE}`,
      {
        url,
        val,
      },
      getReqConfig()
    )
  );
}

export async function getDynamicText_req(url, locale = LocalesEnum.DEFAULT) {
  return parseResponse(axiosInstanceV1.get(`${locale}/Headers?url=${url}`));
}
