import { axiosInstanceV1, getReqConfig } from './axiosInstance';
import { parseResponse } from './helper';
import LocalesEnum from '../enums/locales.enum';

const BASE = '';

export async function getProviders_req() {
  return parseResponse(axiosInstanceV1.get(`${BASE}/Providers`));
}

export async function getGameTypes_req(cookies = {}) {
  return parseResponse(
    axiosInstanceV1.get(`${BASE}/GameTypes`, getReqConfig(cookies))
  );
}

export async function getCategories_req(
  cookies = {},
  locale = LocalesEnum.DEFAULT
) {
  return parseResponse(
    axiosInstanceV1.get(`${BASE}/${locale}/Categories`, getReqConfig(cookies))
  );
}

export async function getSlider_req({
  Key,
  IsMobile,
  IsStatic,
  culture = LocalesEnum.DEFAULT,
}) {
  return parseResponse(
    axiosInstanceV1.get(`${BASE}/${culture}/Slider`, {
      params: { Key, IsMobile, IsStatic },
    })
  );
}
