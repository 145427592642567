import React from 'react';
import { LogoLoader } from 'bf-ui';
import Div100vh from 'react-div-100vh';

import styles from './errorBoundary.wrapper.scss';

import CookiesEnum from '../../../enums/cookies.enum';
import { getItem, setItem } from '../../../helpers/cookie.helper';

class ErrorBoundaryWrapper extends React.Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // eslint-disable-next-line no-alert
    if (window) {
      const happenedCount =
        parseInt(getItem(CookiesEnum.ERROR_BOUNDARY_CATCH), 10) || 1;
      if (happenedCount < 5) {
        setItem(CookiesEnum.ERROR_BOUNDARY_CATCH, happenedCount + 1, true, 1);
        window.location.href = '/';
      }
      return { hasError: true };
    }
    return { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.log({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <div className="error_boundary_wrapper">
            {/*<h1>New version is available!</h1>*/}
            {/*<a href="/">*/}
            {/*  <Button variant="contained">Refresh</Button>*/}
            {/*</a>*/}
            <Div100vh className="error_boundary_wrapper">
              <LogoLoader />
            </Div100vh>
          </div>
          <style jsx>{styles}</style>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundaryWrapper;
