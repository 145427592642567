import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  userName: null,
  firstName: null,
  lastName: null,
  birthDate: null,
  phone: null,
  token: null,
  state: null,
  balance: 0,
  operatorID: null,
  medias: null,
};

export const activationUserSlice = createSlice({
  name: 'activationUser',
  initialState,
  reducers: {
    setActivationUser: (state, action) => {
      const {
        id,
        token,
        userName,
        firstName,
        lastName,
        birthDate,
        phone,
        balance,
        operatorID,
        medias,
      } = action.payload;

      state.id = id;
      state.token = token;
      state.userName = userName;
      state.firstName = firstName;
      state.lastName = lastName;
      state.birthDate = birthDate;
      state.phone = phone;
      state.balance = balance;
      state.operatorID = operatorID;
      state.medias = medias;
    },
    removeActivationUser: () => initialState,
  },
});

export const { setActivationUser, removeActivationUser } =
  activationUserSlice.actions;

export default activationUserSlice.reducer;
