import { configureStore } from '@reduxjs/toolkit';

import reducers from './reducers';

export function initializeStore(initData) {
  return configureStore({
    reducer: reducers,
    preloadedState: initData,
  });
}
