import { combineReducers } from 'redux';

import global from './global.slice';
import user from './user.slice';
import activationUser from './activationUser.slice';
import recent from './recent.slice';

const reducers = combineReducers({
  global,
  user,
  activationUser,
  recent,
});

export default reducers;
