import { isClient } from './detector.helper';
import { getItem } from './cookie.helper';

import TokensEnum from '../enums/tokens.enum';

export const getToken = (cookies = {}) => {
  if (!isClient()) {
    return cookies[TokensEnum.SLOT_TOKEN];
  }

  return getItem(TokensEnum.SLOT_TOKEN);
};

export const getRefreshToken = (cookies = {}) => {
  if (!isClient()) {
    return cookies[TokensEnum.SLOT_REFRESH_TOKEN];
  }

  return getItem(TokensEnum.SLOT_REFRESH_TOKEN);
};
